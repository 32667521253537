
import React, { useRef, useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { gsap } from 'gsap/all';
import styles from './stylesProgramme.module.scss';


export default function ViewStep ( { item, currStep } ) {
    
    // const display = step === currStep ? 'block' : 'none'; //only if it's the currently active step

    const refContainer = useRef(null);
    const [ isActive, setIsActive ] = useState(false);
   
    useEffect(() => {

        if (item.step <= currStep) {
            if (!isActive) {
                setIsActive(true);
                refContainer.current.left = '-10px'
                gsap.to(refContainer.current, 0.3, { opacity: 1, left: '0px' } );
            }
        } else if (item.step > currStep) {
            if (isActive) {
                setIsActive(false);
                gsap.to(refContainer.current, 0.3, { opacity: 0, left: '-10px' } );
            }
        }
        
    }, [item, currStep, isActive]);

    return (
        <div className={ styles.step } ref={ refContainer }>
            <Img fixed={ item.overlayNode.childImageSharp.fixed } alt='step' />
        </div>
    );

}