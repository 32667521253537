import React from 'react'
import styles from './stylesProgramme.module.scss';
import btnBkg1 from './btn--programme-1.png';
import btnBkg2 from './btn--programme-2.png';


export default function ButtonProgrammeItem ( { item, currStep, selectStep } ) {
  
  return (
    <button 
      className={ item.step === currStep ? styles.btn__active : styles.btn } 
      style={ { left: item.btnXPos, top: item.btnYPos } }
      onClick={ () => selectStep(item.step) } 
    >
      { item.step === 1
        ? <img src={ btnBkg1 } width='513' height='63' alt='button bkg' /> 
        : <img src={ btnBkg2 } width='538' height='63' alt='button bkg' /> 
      }
      <h2>{ item.title }</h2>
    </button>
  );
}
