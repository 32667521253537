
export const data = [
    { 
        title: 'Planning',
        step: 1,
        overlaySrc: 'frg--programme-1.png',
        overlayNode: null,
        btnXPos: 150, 
        btnYPos: 461
    },
    { 
        title: 'Making it happen',
        step: 2, 
        overlaySrc: 'frg--programme-2.png',
        overlayNode: null,
        btnXPos: 660, 
        btnYPos: 461
    },
    { 
        title: 'Delivery',
        step: 3,
        overlaySrc: 'frg--programme-3.png',
        overlayNode: null,
        btnXPos: 1183, 
        btnYPos: 461
    }
];