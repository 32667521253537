
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { data } from './dataProgramme';
import ViewProgrammeBkg from './ViewProgrammeBkg';
import ViewProgrammeItem from './ViewProgrammeItem';
import ButtonProgrammeItem from './ButtonProgrammeItem';
import styles from './stylesProgramme.module.scss';


export default function ViewProgramme () {
    
    const [ currStep, setCurrStep ] = useState(0);
    const onStepSelected = (step) => {
        const newStep = step;
        setCurrStep(newStep);
    }

    const stepsQuery = useStaticQuery(graphql`
	{
		allFile(filter: {relativeDirectory: {eq: "programme"}, base: {regex: "/frg/"}}) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 1920, height: 1080, quality: 80) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
					}
				}
			}
		}
	}
    `);
    
    const getImgNodes = () => { //use the retrieved file names to match the data up and assign the nodes for Gatsby IMG
        const imagesList = stepsQuery.allFile.edges;
        data.forEach( item => {
            const matchedImgNode = imagesList.find( img => img.node.base === item.overlaySrc ); 
            item.overlayNode = matchedImgNode.node;
        });
    }
    getImgNodes();

    const getSteps = () => {
        const steps = data.map( (item, index) => 
            <ViewProgrammeItem key={ index } item={ item } currStep={ currStep } />
        );
        return steps;
    }

    const getButtons = () => {
        const buttons = data.map( item => 
            <li key={ item.title }>
                <ButtonProgrammeItem key={ item.title } item={ item } currStep={ currStep } selectStep={ onStepSelected } />
            </li>
        );
        return buttons;
    }

    return (
        <>
            <ViewProgrammeBkg />
            <div className={ styles.frg }>
                { getSteps() }
            </div>
            <nav>
                <ul>
                    { getButtons() }
                </ul>
            </nav>
        </>
    );

}