import React from 'react';
import SEO from '../components/seo/SEO';
import ViewProgramme from '../components/programme/ViewProgramme';


export default function NextStepsProgramme () { 

  return (
    <>
      <SEO title='Next Steps Programme' />
      <ViewProgramme />
    </>
  );

}
